
import AppTitle from '@/components/ui/AppTitle.vue';
import { defineComponent, ref } from 'vue';
import { RouteLocationNormalizedLoaded, Router, useRoute, useRouter } from 'vue-router';
import { ArticleModel } from '@/models/article.model';
import { StoryblokResult, StoryData } from 'storyblok-js-client';
import { fetchArticlesByAuthor, fetchAuthor } from '@/composables/api';
import { parseStoryblokResultToEntity, parseStoryblokResultToStoryData } from '@/composables/storyblokResultParser';
import { AuthorModel } from '@/models/author.model';
import ArticlesCardList from '@/components/ui/ArticlesCardList.vue';
import usePagination, { FetchFnReturn } from '@/composables/usePagination';
import useSlugWatch from '@/composables/useSlugWatch';

export default defineComponent({
  components: { AppTitle, ArticlesCardList },
  setup() {
    const author = ref<AuthorModel | null>();
    const route: RouteLocationNormalizedLoaded = useRoute();
    const router: Router = useRouter();

    const fetchFn = async (page: number): Promise<FetchFnReturn<StoryData<ArticleModel>>> => {
      const uuid = (author.value as AuthorModel).uuid;

      const result: StoryblokResult = await fetchArticlesByAuthor(uuid, page);

      return {
        items: parseStoryblokResultToStoryData(result),
        total: result.total,
        perPage: result.perPage,
      };
    };

    const { next: nextPage, isLoading, items: articles, page, totalPages, totalItems, reset } = usePagination(fetchFn);

    const fetchAuthorAndArticles = async () => {
      const auth = await fetchAuthor(route.params.slug as string);

      const selectedAuthor: AuthorModel = parseStoryblokResultToEntity<AuthorModel>(auth);

      if (selectedAuthor) {
        author.value = selectedAuthor;
        await fetchArticles();
      } else {
        await router.push('/articles');
      }
    };

    const fetchArticles = async () => {
      if (!author.value) {
        return;
      }

      await nextPage();
    };

    useSlugWatch(() => {
      author.value = null;
      reset();
      fetchAuthorAndArticles();
    });

    return {
      articles,
      author,
      totalItems,
      isLoading,
      fetchArticles,
      totalPages,
      page,
    };
  },
});
